<div class="pdmp-tab-contents-container"
     *ngIf="pdmpStateInfo$ | ngrxPush as pdmpStateInfo">
  <div class="pdmp-report-info">
    <button type="button"
            omgButton
            variant="primary"
            class="overlay-top"
            [disabled]="!pdmpStateInfo.hasParticipatingState">
      Open in a new tab
      <i class="fa-regular fa-arrow-up-right-from-square new-tab-icon"></i>
    </button>
    <p class="report-for-text">Report for {{pdmpStateInfo.defaultReportState}}</p>
    <p class="last-viewed-by-text">
      Last viewed by Kiran Ivan at 8:54am on 6/22/2022
    </p>
    <div *ngIf="pdmpReportInfo$ | ngrxPush as pdmpReportInfo">
      <omg-pdmp-narx-scores-view [narxScores]="pdmpReportInfo.narxScores"></omg-pdmp-narx-scores-view>
    </div>
  </div>
  <div class="report-container">
    <ng-template *ngIf="pdmpStateInfo.hasParticipatingState; else noReportNonParticipatingError"
                 [ngTemplateOutlet]="reportContents">
    </ng-template>
  </div>
</div>

<ng-template #reportContents>
  <div *ngIf="pdmpReportInfo$ | ngrxPush as pdmpReportInfo; else loadingPdmpReport">
    <omg-pdmp-report-view [reportUrl]="pdmpReportInfo.reportUrl"></omg-pdmp-report-view>
  </div>
</ng-template>

<ng-template #loadingPdmpReport>
  <div class="loading-container">
    <p class="display-text">
      Report will display here
    </p>
    <i class="fa-regular fa-spinner fa-spin-pulse"></i>
  </div>
</ng-template>

<ng-template #noReportNonParticipatingError>
  <div class="error-text">
    <div>
      Report not available for requested location.
      <br />
      Review state report for patient outside of 1Life.
    </div>
  </div>
</ng-template>
