export type ReferenceDataErrorPayload = any;

export interface RestEndpointMetadata {
  type: 'rest';
  apiEndpoint: string;
}

export interface GraphQLEndpointMetadata {
  type: 'apollo';
  graphQLQuery: string;
  graphQLQueryOptions?: any;
  responseKey: string;
}

export type ReferenceDataMetadata =
  | RestEndpointMetadata
  | GraphQLEndpointMetadata;

export enum ReferenceDataKeys {
  allSpecialties = 'allSpecialties',
  nuccProviderSpecialties = 'nuccProviderSpecialties',
  followUpOrderAppointmentTypes = 'followUpOrderAppointmentTypes',
  followUpOrderProviders = 'followUpOrderProviders',
  growthPercentiles = 'growthPercentiles',
  growthPercentileData = 'growthPercentileData',
  labOrderSets = 'labOrderSets',
  letterTemplates = 'letterTemplates',
  measurementOrderTypeSources = 'measurementOrderTypeSources',
  measurementValidationRules = 'measurementValidationRules',
  prnOptions = 'prnOptions',
  medicationFrequencyIntervals = 'medicationFrequencyIntervals',
  renewalDenialReasons = 'renewalDenialReasons',
  changeDenialReasons = 'changeDenialReasons',
  surveys = 'surveys',
  vitalsMeasurementTypesForPatient = 'vitalsMeasurementTypesForPatient',
  labDevices = 'labDevices',
  manualResultEntryLabs = 'manualResultEntryLabs',
  consultantInsuranceTypes = 'consultantInsuranceTypes',
  insightMeasurementTypes = 'insightMeasurementTypes',
  connectedDeviceOrdersMeasurementTypes = 'connectedDeviceOrdersMeasurementTypes',
  familyMemberConditionTypes = 'familyMemberConditionTypes',
  familyRelationshipsTypes = 'familyRelationshipTypes',
  smokingStatusCodes = 'smokingStatusCodes',
  vitalsValidationRanges = 'vitalsValidationRanges',
  restrictedCustomNetworkIds = 'restrictedCustomNetworkIds',
}
export const referenceDataMetadata: Record<
  ReferenceDataKeys,
  ReferenceDataMetadata
> = {
  [ReferenceDataKeys.allSpecialties]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/specialties',
  },
  [ReferenceDataKeys.nuccProviderSpecialties]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/nucc_provider_specialties',
  },
  [ReferenceDataKeys.followUpOrderAppointmentTypes]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/orders/appointment_types?patient_id=:patientId',
  },
  [ReferenceDataKeys.followUpOrderProviders]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/orders/relative_providers?patient_id=:patientId',
  },
  [ReferenceDataKeys.growthPercentiles]: {
    type: 'rest',
    apiEndpoint: '/v2/pediatric/growth_percentiles',
  },
  [ReferenceDataKeys.growthPercentileData]: {
    type: 'rest',
    apiEndpoint: '/v2/pediatric/growth_percentile_data',
  },
  [ReferenceDataKeys.labOrderSets]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/lab_order_sets',
  },
  [ReferenceDataKeys.letterTemplates]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/letter_templates',
  },
  [ReferenceDataKeys.measurementOrderTypeSources]: {
    type: 'rest',
    apiEndpoint:
      '/v2/admin/measurement_order_type_sources?limit_by_current_user=true&patient_id=:patientId',
  },
  [ReferenceDataKeys.measurementValidationRules]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/measurement_types/validation_rules',
  },
  [ReferenceDataKeys.vitalsValidationRanges]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/vitals_validation_ranges',
  },
  [ReferenceDataKeys.prnOptions]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/medication_prns.json',
  },
  [ReferenceDataKeys.medicationFrequencyIntervals]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/medication_frequency_intervals.json',
  },
  [ReferenceDataKeys.renewalDenialReasons]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/patients/:patientId/refill_requests/denial_reasons',
  },
  [ReferenceDataKeys.changeDenialReasons]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/patients/:patientId/rx_changes/denial_reasons',
  },
  [ReferenceDataKeys.surveys]: {
    type: 'rest',
    apiEndpoint: '/v2/surveys',
  },
  [ReferenceDataKeys.vitalsMeasurementTypesForPatient]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/measurement_types/vitals/:patientId',
  },
  [ReferenceDataKeys.labDevices]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/devices',
  },
  [ReferenceDataKeys.manualResultEntryLabs]: {
    type: 'rest',
    apiEndpoint:
      '/v2/admin/measurement_order_type_sources?limit_by_accepts_manually_entered_results=true',
  },
  [ReferenceDataKeys.familyMemberConditionTypes]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/family_health_histories/conditions',
  },
  [ReferenceDataKeys.familyRelationshipsTypes]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/family_health_histories/relationships',
  },
  [ReferenceDataKeys.smokingStatusCodes]: {
    type: 'rest',
    apiEndpoint: '/v2/admin/smoking_statuses/allowed_statuses',
  },
  [ReferenceDataKeys.consultantInsuranceTypes]: {
    type: 'apollo',
    graphQLQuery: `query InsuranceTypes {
      insuranceTypes {
        id
        name
      }
    }`,
    responseKey: 'insuranceTypes',
  },
  [ReferenceDataKeys.insightMeasurementTypes]: {
    type: 'apollo',
    graphQLQuery: `query MeasurementTypes($forInsightsPanel: Boolean!, $patientId: ID!) {
        measurementTypes(forInsightsPanel: $forInsightsPanel, patientId: $patientId) {
          nodes {
            id
            name
            loincCode
          }
        }
      }`,
    graphQLQueryOptions: { forInsightsPanel: true, patientId: ':patientId' },
    responseKey: 'measurementTypes',
  },
  [ReferenceDataKeys.connectedDeviceOrdersMeasurementTypes]: {
    type: 'apollo',
    graphQLQuery: `query MeasurementTypes($forConnectedDeviceOrders: Boolean!) {
        measurementTypes(forConnectedDeviceOrders: $forConnectedDeviceOrders) {
          nodes {
            id
            name
            loincCode
          }
        }
      }`,
    graphQLQueryOptions: {
      forConnectedDeviceOrders: true,
      patientId: ':patientId',
    },
    responseKey: 'measurementTypes',
  },
  [ReferenceDataKeys.restrictedCustomNetworkIds]: {
    type: 'apollo',
    graphQLQuery: `query CustomNetworks($restricted: Boolean!) {
      customNetworks(restricted: $restricted) {
            id
        }
      }`,
    graphQLQueryOptions: { restricted: true },
    responseKey: 'customNetworks',
  },
};
